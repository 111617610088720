const getTranslatedPath = (path, lang) => {
  const pathSegments = path.split("/").filter(Boolean);

  if (lang === "ua") {
    pathSegments.shift();
    return `/${pathSegments.join("/")}`;
  } else {
    return `/${lang}${
      pathSegments.length === 0 ? "" : `/${pathSegments.join("/")}`
    }`;
  }
};

export default getTranslatedPath;
